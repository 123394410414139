<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>维修管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加维修</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="name" label="维修名称"> </el-table-column>
            <el-table-column label="维修房间">
              <template v-slot="scope">
                <el-tag
                  v-for="item in scope.row.room_name"
                  :key="item.room_id"
                  style="margin-right: 3px;"
                  >{{ item.name }}</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="employee_id"
          label="维修"
          :label-width="formLabelWidth"
        >
          <el-select
            @change="handleChange"
            v-model="form.employee_id"
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="room_ids"
          label="房间"
          :label-width="formLabelWidth"
        >
          <el-select
            @change="handleChange"
            v-model="form.room_ids"
            style="width: 100%"
            placeholder="请选择房间"
            clearable
            multiple
          >
            <el-option
              v-for="item in roomList"
              :key="item.room_id"
              :label="item.name"
              :value="item.room_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'
import {
  add,
  getList,
  getInfo,
  edit,
  del,
  getUserList,
  getRoomList,
} from '@/api/room/repair.js'

export default {
  data() {
    return {
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        employee_id: {
          required: true,
          message: '请选择维修',
          trigger: 'blur',
        },
        room_ids: {
          required: true,
          message: '请选择房间',
          trigger: 'blur',
        },
      },
      title: '添加维修',
      addOrEdit: 1,
      formLabelWidth: '120px',

      form: {
        employee_id: '',
        room_ids: '',
      },
      tableData: [],
      userList: [],
      roomList: [],
    }
  },
  created() {
    this.getList()
    this.getUlist()
  },
  methods: {
    async getUlist() {
      const { data: res } = await getUserList()
      const { data: res1 } = await getRoomList({
        page: 1,
        limit: 9999,
        page_code: '',
        group_id: 0,
      })
      console.log(res)
      if (res.code === 1) {
        this.userList = res.data
      } else {
        this.$message.error(res.message)
      }
      if (res1.code === 1) {
        this.roomList = res1.data.data
      } else {
        this.$message.error(res1.message)
      }
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加维修'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        employee_id: '',
        room_ids: '',
      }
      setTimeout(() => {
        this.$refs.formRef.clearValidate()
      }, 30)
      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑维修'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        repair_id: row.repair_id,
      })
      let info = res.data
      // this.form = res.data
      this.form.employee_id = row.employee_id
      this.form.repair_id = row.repair_id
      this.form.status = row.status
      let arr = row.room_ids.split(',')
      for (let i = 0; i < arr.length; i++) {
        arr[i] = Number(arr[i])
      }
      this.form.room_ids = arr
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await del({
            repair_id: row.repair_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            let q = lodash.cloneDeep(this.form)
            q.room_ids = JSON.stringify(q.room_ids)
            console.log(q)
            let formdata = new FormData()
            for (let key in q) {
              formdata.append(key, q[key])
            }
            const { data: res } = await add(formdata)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              let q = lodash.cloneDeep(this.form)
              q.room_ids = JSON.stringify(q.room_ids)
              console.log(q)
              let formdata = new FormData()
              for (let key in q) {
                formdata.append(key, q[key])
              }
              const { data: res } = await edit(formdata)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
