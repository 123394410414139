import { post } from '@/utils/request'


export function add(data) {
    return post('/room/repair/addRepair', data)
}


export function getList(data) {
    return post('/room/repair/repairList', data)
}

export function getInfo(data) {
    return post('/room/repair/repairInfo', data)
}

export function edit(data) {
    return post('/room/repair/editRepair', data)
}

export function del(data) {
    return post('/room/repair/delRepair', data)
}


export function getUserList(data) {
    return post('admin/storage/getUserList', data)
}

export function getRoomList(data) {
    return post('/room/room/roomList', data)
}